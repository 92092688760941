.input {
    padding: 15px;
    font-size: 15px;
    box-shadow: 0px 0px 30px #22d6aa33;
    margin-bottom: 15px;
    background: #ffffff;
    outline: none;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    min-width: 570px;
  }
  
  .registerbox {
    padding: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #22d6aa33;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 610px;
    width: 100%;
    margin: 0 auto;
  }
  
  .registerbutton {
    padding: 15px;
    background: #ffffff;
    border: 2px solid #22d6aa;
    color: #1f2e39;
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    border-radius: 0 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    min-width: 570px;
    border-color: #22d6aa;
  }
  .registerbutton:hover {
    border-color: #22d6aa;
  }
  .errormessage {
    width: 100%;
    color: rgb(248, 0, 41);
    background-color: rgb(245, 213, 218);
    margin: 10px 0;
    border-radius: 5px;
    padding: 5px 10px;
  }
  
  .registerbutton:hover {
    transition: 0.5s;
    background: #22d6aa;
  }
  
  .fullwidthcontainer {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
  }
  
  .containerblock {
    position: absolute;
    display: block;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    padding: 45px 50px;
    background-image: url("../../../public/images/Border.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  @media only screen and (max-width: 600px) {
    .registerbox {
      width: 100%;
    }
  
    .container {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      padding: 20px;
      border-radius: 20px;
    }
  }
  