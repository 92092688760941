@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.loginbox {
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #22d6aa33;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  width: 50%;
}

.logo {
  width: 152px;
  height: 30px;
  display: flex;   
  margin: 0 auto;
  margin-top: 39.7px;
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font: normal normal bold 30px/1 'Rubik', sans-serif; 
  text-align: center;
}

.btnloginicon {
  height: 20px;
}

.button2hover {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.btnlogin {
  margin: 0 auto;
  padding: 20px;
  cursor: pointer;
  border: none;
  background-color: #ffffff;
  opacity: 1;
  outline: none;
  font: normal normal medium 15px/1.5 'Rubik', sans-serif;
  color: #1f2e39;
  text-transform: uppercase;
  display: flex;
  gap: 50px;
  background-image: url(/public/images/button2hover.svg), url(/public/images/button2.svg);
  background-position: left bottom, right top;
  background-size: 85% 80%, 100% 100%;
  background-repeat: no-repeat, no-repeat;
}

.btnlogin:hover {
  background-image: url(/public/images/button2.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.btnlogin:hover .btnloginicon {
  transform: rotate(45deg);
  transition: 0.5s;
}

.input {
  width:100%;
  height: 45px;
  font-size: 15px;
  font: normal normal normal 15px/1.5 'Rubik', sans-serif;
  border: 1px solid #708EA4;
  margin-bottom: 15px;
  background: #ffffff;
  outline: none;
  border-radius: 5px;
  padding: 0 40px 0 10px; 
}
.input::placeholder {
  color: #708EA4;
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.icon {
  position: absolute;
  margin-bottom: 10px;
  right: 20px; 
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 1;
  /* transition: opacity 0.3s; */
}

/* .inputWrapper:hover .icon {
  opacity: 1;
} */
.label {
  padding-bottom: 8px;
  color: #1F2E39;
  text-transform: uppercase;
}

.errormessage {
  width: 95%;
  color: rgb(248, 0, 41);
  background-color: rgb(245, 213, 218);
  border-radius: 5px;
  padding: 10px;
}

.loginbutton:hover {
  transition: 0.5s;
  background: #22d6aa;
}

.Loginmain {
  width: 600px;
  background-image: url('/public/images/Border.png');
  background-repeat: no-repeat;
  margin: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}

@media only screen and (max-width: 700px) {
  .loginbox {
    width: 100%;
    padding: 10px;
  }
  
  .logo {
    width: 152px;
    height: 30px;
    display: flex;   
    margin: 0 auto;
    margin-top: 29.7px;
  }
  
  .header {
    font-size: 24px;
    width: 100%;
  }

  .btnlogin {
    padding: 15px;
    gap: 20px;
    background-size: 75% 75%, 100% 100%;
  }
  
  .input {
    font-size: 14px;
    width: 100%;
  }
  
  .label {
    font-size: 14px;
  }
  
  .errormessage {
    font-size: 14px;
    padding: 5px 10px;
  }
  
  .Loginmain {
    max-width: 100%;
    width: 450px;
    height: auto;
    padding: 40px;
    background-image: url('/public/images/Border.png');
    background-repeat: no-repeat;
    margin: auto;
    display: block;
    align-items: center;
   

  }
}

@media only screen and (max-width: 600px) {
  .loginbox {
    width: 100%;
    padding: 10px;
  }
  
  .logo {
    width: 152px;
    height: 30px;
    display: flex;   
    margin: 0 auto;
    margin-top: 20.7px;
  }
  
  .inputWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }


  .header {
    font-size: 24px;
    width: 100%;
  }

  .btnlogin {
    padding: 15px;
    gap: 20px;
    background-size: 75% 75%, 100% 100%;
  }
  
  .input {
    font-size: 14px;
   
   width: 90%;
  }
  
  .label {
    font-size: 14px;
    /* margin-left: 12px; */
  }
  
  .errormessage {
    font-size: 14px;
    padding: 5px 10px;
  }

  .Loginmain {
    max-width: 100%;
    width:auto;
    height: auto;
    background-image: url('/public/Border.svg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
}

@media only screen and (max-width: 400px) {
  .loginbox {
    width: 100%;
    padding: 10px;
  }
  
  .logo {
    width: 152px;
    height: 30px;
    display: flex;   
    margin: 0 auto;
    margin-top: 18.7px;
  }
  
  .header {
    font-size: 24px;
    width: 100%;
  }

  .inputWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .btnlogin {
    padding: 15px;
    gap: 20px;
    background-size: 75% 75%, 100% 100%;
  }
  
  .input {
    font-size: 14px;
   
   width: 90%;
  }
  .label {
    font-size: 14px;
  }
  
  .errormessage {
    font-size: 14px;
    padding: 5px 10px;
  }

  .Loginmain {
    max-width: 100%;
    width:74%;
    height: auto;
    background-image: url('/public/Border.svg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
}
